<template>
  <v-container
    id="regular-forms"
    fluid
    tag="section"
  >
    <v-row>
      <v-col cols="12">
        <base-material-card
          color="success"
          inline
          title="Cliente"
          class="px-5 py-3"
        >
          <v-form class="pt-1" @submit.prevent="postClient">
            <v-row>
              <v-col cols="6">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="CPF/CNPJ"
                >
                  <v-text-field
                    label="CPF/CNPJ *"
                    outlined
                    class="ml-4"
                    :error-messages="errors"
                    v-model="cpf"
                    v-mask="['###.###.###-##', '##.###.###/####-##']"
                  />

                </validation-provider>
              </v-col>
              <v-col cols="6">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="Nome"
                >
                  <v-text-field
                    label="Nome *"
                    outlined
                    class="ml-4"
                    :error-messages="errors"
                    v-model="name"
                  />

                </validation-provider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="Whatsapp"
                  outlined
                  class="ml-4"
                  v-model="whatsapp"
                  v-mask="'(##)#-####-####'"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Telefone/Celular"
                  outlined
                  class="ml-4"
                  v-model="tel"
                  v-mask="['(##)####-####', '(##)#####-####']"
                />

              </v-col>
            </v-row>

            <v-text-field
              label="Email"
              outlined
              class="ml-4"
              v-model="email"
            />

            <v-subheader class="display-1 mt-3">
              Endereço:
            </v-subheader>

            <v-row>
              <v-col md="2" sm="12" xs="12">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="CEP"
                >
                  <v-text-field
                    label="CEP *"
                    outlined
                    class="ml-4"
                    :error-messages="errors"
                    v-model="cep"
                    @keyup="searchCep()"
                    v-mask="'##.###-###'"
                  />
                </validation-provider>
              </v-col>
            </v-row>


            
            <v-row>
              <v-col cols="6">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="Logradouro"
                >
                  <v-text-field
                    label="Logradouro *"
                    outlined
                    class="ml-4"
                    :error-messages="errors"
                    v-model="address"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="3">


                <v-text-field
                  label="Complemento"
                  outlined
                  class="ml-4"
                  v-model="complement"
                />

              </v-col>
              <v-col cols="3">
                <v-text-field
                  label="Número"
                  outlined
                  class="ml-4"
                  :error-messages="errors"
                  v-model="number"
                />
              </v-col>
            </v-row>
  
            <v-row>
              <v-col cols="4">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="Cidade"
                >
                  <v-text-field
                    label="Cidade *"
                    outlined
                    class="ml-4"
                    :error-messages="errors"
                    v-model="city"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="4">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="Bairro"
                >
                  <v-text-field
                    label="Bairro *"
                    outlined
                    class="ml-4"
                    :error-messages="errors"
                    v-model="district"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="4">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="Estado"
                >
                  <v-text-field
                    label="Estado *"
                    outlined
                    class="ml-4"
                    :error-messages="errors"
                    v-model="state"
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <v-row>
              <v-textarea
                name="input-7-1"
                label="Observação"
                value=""
                hint="observações"
                class="ml-4"
                v-model="ps"
              />
            </v-row>

            <v-card-actions class="pl-0">
              <v-btn
                color="success"
                min-width="100"
                type="submit"
              >
                Salvar
              </v-btn>
            </v-card-actions>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import ClientsService from '@/services/ClientsService'
  import ServiceRequestsService from '@/services/ServiceRequestsService'
  import axios from 'axios'

  export default {
    name: 'EditClient',

    components: { 

    },

    data: () => ({
      name: "",
      document: "",
      whatsapp: "",
      email: "",
      tel: "",
      productmodel: "",
      failure: "",
      cpf: "",
      cep: "",
      address: "",
      complement: "",
      city: "",
      district: "",
      state: "",
      number: '',
      ps: ""
    }),

    mounted () {
      if(this.$route.params.id){
        this.getClient(this.$route.params.id)
      }
      if(this.$route.params.requestId){
        this.getRequest(this.$route.params.requestId)
      }
    },

    computed: {
      
    },

    methods: {

      searchCep () {
        if(this.cep.length == 10) {
          axios.get(`https://viacep.com.br/ws/${ this.cep.replace('-', '').replace('.', '') }/json/`)
          .then((response) => {
            this.address = response.data.logradouro
            this.city = response.data.localidade
            this.district = response.data.bairro
            this.state = response.data.uf
          })
          .catch( error => console.log(error) )
        }
      },

      postClient() {
        let data = []

        data['name'] = this.name,
        data['document'] = this.document,
        data['whatsapp'] = this.whatsapp,
        data['email'] = this.email,
        data['tel'] = this.tel,
        data['cpf'] = this.cpf,
        data['cep'] = this.cep,
        data['address'] = this.address,
        data['complement'] = this.complement,
        data['city'] = this.city,
        data['district'] = this.district,
        data['state'] = this.state,
        data['number'] = this.number,
        data['ps'] = this.ps


        ClientsService.postClient(data,this.$route.params.id)
        .then((response) => {
          console.log(response)
          if(response.data.message == "Error"){
            throw 500
          }
          this.$toast.success('Cliente Salvo Sucesso.', '',{position:'topRight'})
          this.$router.push({ name: 'Clientes' })
        })
        .catch(error => {
          console.log(error)
          this.$toast.error('Erro ao salvar.', '',{position:'topRight'})
        })
      },

      getRequest(idRequest) {
        ServiceRequestsService.getServiceRequest(idRequest)
        .then(response => {

          this.name = response.data.service_request.name,
          this.document = response.data.service_request.document,
          this.whatsapp = response.data.service_request.whatsapp,
          this.email = response.data.service_request.email,
          this.tel = response.data.service_request.tel,
          this.cpf = response.data.service_request.cpf,
          this.cep = response.data.service_request.cep,
          this.address = response.data.service_request.address,
          this.complement = response.data.service_request.complement,
          this.city = response.data.service_request.city,
          this.district = response.data.service_request.district,
          this.state = response.data.service_request.state,
          this.number = response.data.service_request.number,
          this.ps = response.data.service_request.ps
        })
        .catch(error => {
          console.log(error)
          this.$toast.error('Erro ao recuperar cliente.', '',{position:'topRight'})
        })
      },

      getClient(id) {
        ClientsService.getClient(id)
        .then(response => {

          this.name = response.data.client.name,
          this.document = response.data.client.document,
          this.whatsapp = response.data.client.whatsapp,
          this.email = response.data.client.email,
          this.tel = response.data.client.tel,
          this.cpf = response.data.client.cpf,
          this.cep = response.data.client.cep,
          this.address = response.data.client.address,
          this.complement = response.data.client.complement,
          this.city = response.data.client.city,
          this.district = response.data.client.district,
          this.state = response.data.client.state,
          this.number = response.data.client.number,
          this.ps = response.data.client.ps
        })
        .catch(error => {
          console.log(error)
          this.$toast.error('Erro ao recuperar cliente.', '',{position:'topRight'})
        })
      },
    },
  }
</script>