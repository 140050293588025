var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"regular-forms","fluid":"","tag":"section"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"color":"success","inline":"","title":"Cliente"}},[_c('v-form',{staticClass:"pt-1",on:{"submit":function($event){$event.preventDefault();return _vm.postClient($event)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"CPF/CNPJ"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(['###.###.###-##', '##.###.###/####-##']),expression:"['###.###.###-##', '##.###.###/####-##']"}],staticClass:"ml-4",attrs:{"label":"CPF/CNPJ *","outlined":"","error-messages":errors},model:{value:(_vm.cpf),callback:function ($$v) {_vm.cpf=$$v},expression:"cpf"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Nome"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ml-4",attrs:{"label":"Nome *","outlined":"","error-messages":errors},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('(##)#-####-####'),expression:"'(##)#-####-####'"}],staticClass:"ml-4",attrs:{"label":"Whatsapp","outlined":""},model:{value:(_vm.whatsapp),callback:function ($$v) {_vm.whatsapp=$$v},expression:"whatsapp"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(['(##)####-####', '(##)#####-####']),expression:"['(##)####-####', '(##)#####-####']"}],staticClass:"ml-4",attrs:{"label":"Telefone/Celular","outlined":""},model:{value:(_vm.tel),callback:function ($$v) {_vm.tel=$$v},expression:"tel"}})],1)],1),_c('v-text-field',{staticClass:"ml-4",attrs:{"label":"Email","outlined":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-subheader',{staticClass:"display-1 mt-3"},[_vm._v(" Endereço: ")]),_c('v-row',[_c('v-col',{attrs:{"md":"2","sm":"12","xs":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":"CEP"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##.###-###'),expression:"'##.###-###'"}],staticClass:"ml-4",attrs:{"label":"CEP *","outlined":"","error-messages":errors},on:{"keyup":function($event){return _vm.searchCep()}},model:{value:(_vm.cep),callback:function ($$v) {_vm.cep=$$v},expression:"cep"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Logradouro"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ml-4",attrs:{"label":"Logradouro *","outlined":"","error-messages":errors},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}})]}}])})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{staticClass:"ml-4",attrs:{"label":"Complemento","outlined":""},model:{value:(_vm.complement),callback:function ($$v) {_vm.complement=$$v},expression:"complement"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{staticClass:"ml-4",attrs:{"label":"Número","outlined":"","error-messages":_vm.errors},model:{value:(_vm.number),callback:function ($$v) {_vm.number=$$v},expression:"number"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Cidade"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ml-4",attrs:{"label":"Cidade *","outlined":"","error-messages":errors},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}})]}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Bairro"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ml-4",attrs:{"label":"Bairro *","outlined":"","error-messages":errors},model:{value:(_vm.district),callback:function ($$v) {_vm.district=$$v},expression:"district"}})]}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Estado"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ml-4",attrs:{"label":"Estado *","outlined":"","error-messages":errors},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}})]}}])})],1)],1),_c('v-row',[_c('v-textarea',{staticClass:"ml-4",attrs:{"name":"input-7-1","label":"Observação","value":"","hint":"observações"},model:{value:(_vm.ps),callback:function ($$v) {_vm.ps=$$v},expression:"ps"}})],1),_c('v-card-actions',{staticClass:"pl-0"},[_c('v-btn',{attrs:{"color":"success","min-width":"100","type":"submit"}},[_vm._v(" Salvar ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }